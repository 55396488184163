import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useSearchBox } from "react-instantsearch";
import { SearchSvg } from "../../assets";

const CustomSearchBoxModal = ({
    initialQuery,
    onQueryChange,
    onMoreResults = () => null,
    colorObj,
    isSearchModalOpen,
}) => {
    const { refine } = useSearchBox();
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState("");

    function setQuery(newQuery) {
        setInputValue(newQuery);
        refine(newQuery);
        onQueryChange(newQuery);
    }

    useEffect(() => {
        setInputValue(initialQuery || "");
    }, [initialQuery]);

    useEffect(() => {
        if (isSearchModalOpen && inputRef.current) {
            inputRef.current.focus();
        } else {
            setQuery("");
        }
    }, [isSearchModalOpen]);

    return (
        <div className="modal-search-bar d-flex">
            <i className="search-icon">
                <SearchSvg colorObj={colorObj} />
            </i>
            <InputText
                type="text"
                ref={inputRef}
                className="p-inputtext-lg"
                placeholder="Enter a search keyword"
                value={inputValue}
                onChange={(event) => setQuery(event.currentTarget.value)}
            />

            <i
                className="pi pi-arrow-right arrow-icon"
                aria-hidden="true"
                onClick={onMoreResults}
            ></i>
        </div>
    );
};

export default CustomSearchBoxModal;
