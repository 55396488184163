import React from "react";
import moment from "moment";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { calculateReadTime } from "../../utils/articleHelpers";
import useCustomTranslation from "../../utils/useCustomTranslation";
import PlayIconSvg from "../../assets/PlayIconSvg";

function TagCard2({ content, colorObj }) {
    const navigate = useNavigate();

    const { image, title, author, date, summary, tags, nid, posterImage, link } = content;

    const tag = tags.split(",")[0];

    const handleClick = () => navigate(`/articles/${nid}`);

    const { t } = useCustomTranslation();

    return (
        <>
            <div className="newsCard card">
                <div className="cardImg">
                    {!!link && (
                        <div
                            className="playIconHldr"
                            style={{
                                "--accent_color4": colorObj.accent_color4,
                            }}
                        >
                            <PlayIconSvg />
                        </div>
                    )}
                    <ImageWithObjectFit
                        className="cursor-pointer"
                        src={image?.src || posterImage}
                        onClick={handleClick}
                    />
                </div>
                <div className="card-body d-flex flex-column text-start">
                    <div
                        className="tag-heading mb-2"
                        style={{
                            "--accent_color3": colorObj?.accent_color3,
                            "--primary_color": colorObj?.primary_color,
                        }}
                    >
                        {calculateReadTime(summary)
                            ? `${calculateReadTime(summary)} ${t("constantString.minRead")} `
                            : ""}
                    </div>
                    <h3
                        className="underline"
                        onClick={handleClick}
                        style={{ "--accent_color1": colorObj?.accent_color1 }}
                    >
                        {title}
                    </h3>
                    <div className="date" style={{ "--accent_color3": colorObj?.accent_color1 }}>
                        {author}
                        {author && date ? " | " : ""}
                        {date ? moment(date).format(`MMM DD${","} YYYY`) : ""}
                    </div>
                    <div
                        className="textMain"
                        style={{ "--accent_color3": colorObj?.accent_color3 }}
                    >
                        {summary ? parse(summary) : ""}
                    </div>
                    {tag !== "" && (
                        <div className="d-flex mt-auto">
                            <div
                                className="hashtag"
                                style={{
                                    "--primary_color": colorObj?.primary_color,
                                    "--accent_color2": colorObj?.accent_color2,
                                }}
                            >
                                #{tag}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default TagCard2;
