import ImageWithObjectFit from "../ImageWrapperComponent";
import { calculateReadTime } from "../../utils/articleHelpers";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { useGetLink } from "../../utils/useOpenLink";
import { Link } from "react-router-dom";
import useGetArticleMeta from "../../utils/useGetArticleMeta";
import { ZERO_WIDTH_CHAR } from "../../constants/displayConstants";
import PlayIconSvg from "../../assets/PlayIconSvg";

export const RelatedCard1 = ({ content, colorsObj }) => {
    const { image, title: Title, path, posterImage, breadcrumb, link } = content;

    const { t } = useCustomTranslation();

    const { getLink } = useGetLink();

    const { getTheme, getSection } = useGetArticleMeta();
    const theme = getTheme(breadcrumb);
    const section = getSection(breadcrumb);

    return (
        <div className="newsCard card">
            <div className="cardImg">
                {!!link && (
                    <div
                        className="playIconHldr"
                        style={{
                            "--accent_color4": colorsObj.accent_color4,
                        }}
                    >
                        <PlayIconSvg />
                    </div>
                )}
                <Link to={getLink(path)}>
                    <ImageWithObjectFit
                        className="cursor-pointer"
                        src={image?.src || posterImage}
                    />
                </Link>
            </div>

            <div className="card-body d-flex flex-column text-start">
                <div className="tag-heading mb-2" style={{ color: colorsObj.primary_color }}>
                    {theme || ZERO_WIDTH_CHAR}
                </div>
                <Link to={getLink(path)}>
                    <h3
                        className="hover:underline cursor-pointer"
                        style={{ color: colorsObj.accent_color1 }}
                    >
                        {Title}
                    </h3>
                </Link>
                <div className="d-flex mt-auto">
                    {theme && (
                        <div
                            className="author me-1 capitalize"
                            style={{ color: colorsObj.accent_color1 }}
                        >
                            {section}
                        </div>
                    )}
                    <div className="date" style={{ color: colorsObj.accent_color3 }}>
                        {section && calculateReadTime(content) ? " • " : ""}
                        {calculateReadTime(content)
                            ? `${calculateReadTime(content)} ${t("constantString.minRead")}`
                            : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};
