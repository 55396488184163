import React, { useEffect, useState } from "react";
import { useGetLink } from "../../utils/useOpenLink";
import { Link } from "react-router-dom";
import clsx from "clsx";

function SubMenuStyle4({ data }) {
    const { getLink } = useGetLink();

    const [selectedContent, setSelectedContent] = useState(null);

    useEffect(() => {
        if (data.contents && data.contents.length > 0) {
            setSelectedContent(data.contents[0]);
        }
    }, [data.contents]);

    const handleMainHeadingClick = (content) => {
        setSelectedContent(content);
    };

    const renderCategory = (value, index) => {
        const categorySelected = selectedContent?.id === value.id;
        return (
            <div
                className={clsx(
                    "cursor-pointer flex flex-row justify-between items-center mb-2 p-2",
                    categorySelected && "text-primary_color md:bg-accent_color2 rounded-xl",
                )}
                key={index}
                onMouseEnter={() => handleMainHeadingClick(value)}
                onClick={() => {
                    if (categorySelected) {
                        handleMainHeadingClick(null);
                    } else {
                        handleMainHeadingClick(value);
                    }
                }}
            >
                {value.heading}
                <i className={"pi pi-chevron-right hidden md:block"} aria-hidden="true"></i>
                <i
                    className={clsx(
                        "block md:hidden",
                        categorySelected ? "pi pi-chevron-up" : "pi pi-chevron-down",
                    )}
                    aria-hidden="true"
                ></i>
            </div>
        );
    };

    const renderTitleLink = (content) => {
        return (
            <Link to={getLink(content.content_reference)}>
                <div className="font-medium uppercase mb-2 mt-2 px-4 md:mt-0 md:px-0 text-primary_color md:text-accent_color1">
                    {content.heading}
                </div>
            </Link>
        );
    };

    const renderSubContentLink = (item, index) => {
        return (
            <Link key={index} to={getLink(item.content_reference)}>
                <div
                    className={clsx(
                        "py-3 px-4 md:px-0",
                        item.feature === 1 ? "md:font-bold" : "font-normal",
                    )}
                >
                    {item.heading}
                    {item.feature === 1 && (
                        <i
                            className="hidden md:inline-block pi pi-arrow-right mx-2"
                            aria-hidden="true"
                        ></i>
                    )}
                </div>
            </Link>
        );
    };

    return (
        <div className="flex flex-col text-accent_color1 p-4 md:p-12 text-sm flex-1">
            <img src={data.section_image?.src} className="h-11 w-11 object-contain mb-3" />
            <div className="capitalize mb-4 pb-4 border-solid border-b border-b-accent_color2">
                {data.heading}
            </div>

            {/* desktop */}
            <div className="hidden md:flex flex-row">
                <div className="flex flex-col mr-8">
                    {data.contents?.map((value, index) => renderCategory(value, index))}
                </div>

                <div className="flex flex-row flex-nowrap gap-x-8 flex-1">
                    {selectedContent?.contents?.map((subContent, subIndex) => (
                        <div className="flex flex-col pb-8" key={subIndex}>
                            {renderTitleLink(subContent)}
                            {subContent.contents?.map((item, index) =>
                                renderSubContentLink(item, index),
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* mobile */}
            <div className="md:hidden">
                {data.contents?.map((value, index) => {
                    return (
                        <div key={index}>
                            {renderCategory(value, index)}
                            {value?.contents?.map((subContent, subIndex) => (
                                <div
                                    className={clsx(
                                        selectedContent?.id === value.id
                                            ? "flex flex-col"
                                            : "hidden",
                                    )}
                                    key={subIndex}
                                >
                                    {renderTitleLink(subContent)}
                                    {subContent.contents?.map((item, index) =>
                                        renderSubContentLink(item, index),
                                    )}
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default SubMenuStyle4;
