import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FacebookSvg from "../../assets/FacebookSvg";
import InstagramSvg from "../../assets/InstagramSvg";
import TwitterSvg from "../../assets/TwitterSvg";
import YoutubeSvg from "../../assets/YoutubeSvg";
import { APP_COLORS } from "../../constants/styles";
import {
    selectCountriesLanguageArray,
    setLanguageInfo,
    setOpenSearchModal,
} from "../../redux/authSlice";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import DesktopLinkGroup from "./DesktopLinkGroup";
import MobileLinkGroup from "./MobileLinkGroup";
import CustomLink from "../../utils/customLink";
import { CountryLanguagePicker } from "../CountryLanguagePicker";
import SocialLinks from "../SocialLinks";
import { APP_STORE_LINK, PLAY_STORE_LINK, PRIVACY_LINKS } from "../../constants/globalValues";

function FooterComponent3({
    contents: contentsToRender = [],
    block_buttons,
    colors: colorObj,
    section_image,
}) {
    const { getLink } = useGetLink();

    const dispatch = useDispatch();

    const countriesLanguageData = useSelector(
        selectCountriesLanguageArray,
        (prev, next) => prev.length === next.length,
    );

    const [socialButtonsArr, setSocialButtonsArr] = useState([]);

    const [actionButtonsArr, setActionButtonsArr] = useState(PRIVACY_LINKS);

    const [collapsedIndexes, setCollapsedIndexes] = useState([]);

    const [visible, setVisible] = useState(false);

    const [uniqueGeographyAreas, setUniqueGeographyAreas] = useState([]);

    const [activeIndex, setActiveIndex] = useState(0);

    const [initialLanguageSet, setInitialLanguageSet] = useState(false);

    const { t } = useCustomTranslation();

    useEffect(() => {
        if (countriesLanguageData.length) {
            const geographyAreas = countriesLanguageData
                .map((country) => country.geography_area)
                .filter((value, index, self) => value && self.indexOf(value) === index);
            setUniqueGeographyAreas(geographyAreas);
        }
    }, [countriesLanguageData]);

    const LogoAppWrapperScroll = () => {
        window.scrollTo(0, 0);
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleAreaClick = (index, area) => {
        setActiveIndex(index);
        scrollToSection(area.replace(/\s+/g, ""));
    };

    useEffect(() => {
        if (block_buttons && block_buttons.length > 0) {
            const socialBtns = block_buttons.filter(
                (button) =>
                    button.label.toLowerCase() !== "privacy policy" &&
                    button.label.toLowerCase() !== "terms & conditions",
            );
            const actionBtns = block_buttons.filter(
                (button) =>
                    button.label.toLowerCase() === "privacy policy" ||
                    button.label.toLowerCase() === "terms & conditions",
            );

            setSocialButtonsArr(socialBtns);
            setActionButtonsArr(actionBtns);
        }
    }, [block_buttons]);

    const socialIcons = {
        facebook: <FacebookSvg />,
        twitter: <TwitterSvg />,
        instagram: <InstagramSvg />,
        youtube: <YoutubeSvg />,
    };

    const handleToggleCollapse = (index) => {
        setCollapsedIndexes((prevState) =>
            prevState.includes(index)
                ? prevState.filter((i) => i !== index)
                : [...prevState, index],
        );
    };

    useEffect(() => {
        const selectedCountry = localStorage.getItem("country");
        if (selectedCountry && countriesLanguageData.length > 0) {
            const filter = countriesLanguageData.filter((e) => e.country === selectedCountry);

            if (filter.length > 0 && filter[0].language) {
                const arr = filter[0].language.map((iterator) => ({
                    displayName: iterator.label,
                    dir: iterator.direction,
                    code: iterator.code,
                }));

                if (arr.length === 1 && !initialLanguageSet) {
                    setInitialLanguageSet(true);
                }
            }
        }
    }, [countriesLanguageData, initialLanguageSet]);

    const changeLanguage = (language, dir) => {
        dispatch(setLanguageInfo({ code: language, direction: dir }));
        // dispatch(fetchStaticTraslateData(language))
    };

    const renderDesktopLinks = () => {
        return (
            <div className="hidden md:flex flex-row flex-wrap justify-between gap-y-16 gap-x-8 mb-16">
                {contentsToRender.map((value, index) => (
                    <DesktopLinkGroup
                        key={value.heading}
                        index={index}
                        value={value}
                        openLink={getLink}
                    />
                ))}
            </div>
        );
    };

    const renderMobileLinks = () => {
        return (
            <div className="mb-8 md:hidden">
                <div className="w-4/5 mb-4">
                    <img src={section_image?.src} />
                </div>
                <div className="mb-8 text-accent_color3 text-start">{t("footerText.aboutTxt")}</div>

                {contentsToRender.map((value, index) => (
                    <MobileLinkGroup
                        key={value.heading}
                        index={index}
                        value={value}
                        openLink={getLink}
                        handleToggleCollapse={handleToggleCollapse}
                        expanded={collapsedIndexes.includes(index)}
                    />
                ))}
            </div>
        );
    };
    const renderSearchBar = () => {
        return (
            <div className="text-accent_color3 flex flex-row bg-white rounded-2xl items-center px-4 mb-12">
                <i className="pi pi-search"></i>
                <InputText
                    className="grow border-none text-sm"
                    placeholder={t("placeHolder.search")}
                    onClick={() => dispatch(setOpenSearchModal(true))}
                />
            </div>
        );
    };

    const renderAppLogoBlock = () => {
        return (
            <div className="mb-12 flex flex-col relative">
                <div className="flex flex-row justify-between px-3 py-10 text-accent_color3 border-dashed border-accent_color3 border-t border-b">
                    <div className="hidden md:flex md:flex-col">
                        <img src={section_image?.src} className="w-72 mb-12" />
                        <h6>{t("footerText.aboutTxt")}</h6>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:items-end">
                        <CountryLanguagePicker displayType={"footer"} />
                        <div className="mb-3 mr-3 text-sm">
                            {t("footerText.experience")}
                            &nbsp;
                            <span className="text-primary_color">
                                <strong>{t("footerText.ismailiApp")}</strong>
                            </span>
                        </div>
                        <div className="flex flex-row space-x-4">
                            <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
                                <img
                                    className="w-20 md:w-[6.5rem]"
                                    src={location.origin + "/assets/images/appStore-icon.svg"}
                                />
                            </a>
                            <a href={PLAY_STORE_LINK} target="_blank" rel="noreferrer">
                                <img
                                    className="w-20 md:w-[6.5rem]"
                                    src={location.origin + "/assets/images/googlePlay-icon.svg"}
                                />
                            </a>
                        </div>
                        <SocialLinks />
                        <ul>
                            {socialButtonsArr.length > 0 &&
                                socialButtonsArr.map((v) => (
                                    <CustomLink to={getLink(v.action)} key={v.label}>
                                        <li className="cursor-pointer">
                                            {socialIcons[v.label.toLowerCase()] || null}
                                        </li>
                                    </CustomLink>
                                ))}
                        </ul>
                    </div>
                </div>
                <div className="absolute -bottom-5 w-full flex justify-center">
                    <div
                        className="cursor-pointer flex bg-accent_color2 text-accent_color3 border-accent_color3 border rounded-full w-10 h-10 justify-center items-center"
                        onClick={LogoAppWrapperScroll}
                    >
                        <i className="pi pi-angle-up"></i>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div style={APP_COLORS(colorObj)}>
            <div className="px-8 md:px-16 py-12 bg-accent_color2 rounded-t-2xl rtl-footerMain">
                <div className="container">
                    {renderSearchBar()}
                    {renderMobileLinks()}
                    {renderDesktopLinks()}
                    {renderAppLogoBlock()}

                    <div className="text-accent_color3 text-xs flex flex-col gap-y-8 md:flex-row justify-between mb-8">
                        <div>
                            {actionButtonsArr.map((v, index) => {
                                return (
                                    <>
                                        <CustomLink to={getLink(v.action)}>
                                            <span
                                                className="cursor-pointer capitalize text-accent_color3 py-2"
                                                key={v.label}
                                            >
                                                {v.label}
                                            </span>
                                        </CustomLink>

                                        {index != actionButtonsArr.length - 1 ? (
                                            <span className="mx-2 md:invisible">•</span>
                                        ) : null}
                                    </>
                                );
                            })}
                        </div>
                        <div>{t("footerText.copyRight")}</div>
                    </div>
                </div>
            </div>

            <Dialog
                visible={visible}
                style={{ width: "60vw" }}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
                breakpoints={{ "960px": "75vw", "641px": "100vw", "500px": "92vw" }}
            >
                <div className="contLogoHldr">
                    <img src={section_image?.src} />
                </div>
                <div className="contWrapper">
                    <h2 style={{ color: colorObj?.accent_color1 }}>
                        {t("constantString.whereAreYouFrom")}
                    </h2>
                    <div className="contNavHldr" id="nav-container">
                        <ul id="nav">
                            {uniqueGeographyAreas.map((area, index) => (
                                <li
                                    key={area}
                                    className={index === activeIndex ? "active" : ""}
                                    onClick={() => handleAreaClick(index, area)}
                                    style={{
                                        color:
                                            index === activeIndex
                                                ? colorObj?.accent_color4
                                                : colorObj?.accent_color3,
                                        "--primary_color": colorObj?.primary_color,
                                        "--accent_color4": colorObj?.accent_color4,
                                    }}
                                >
                                    {area}
                                </li>
                            ))}
                        </ul>
                        <div className="ContNotlisted" style={{ color: colorObj?.accent_color3 }}>
                            <div className="notlisted">{t("constantString.countryNotListed")}</div>
                            <div className="International">
                                <i
                                    className="pi pi-globe"
                                    style={{ color: colorObj?.primary_color }}
                                ></i>
                                {t("constantString.international")}
                            </div>
                        </div>
                    </div>

                    <div className="contMainWrapper">
                        {uniqueGeographyAreas.map((area) => (
                            <div
                                className="contNameHldr"
                                id={area.replace(/\s+/g, "")}
                                key={`contNameHldr-${area}`}
                            >
                                <h2 style={{ color: colorObj?.accent_color1 }}>{area}</h2>
                                <div className="contNameMain">
                                    {countriesLanguageData
                                        .filter((country) => country.geography_area === area)
                                        .map((country) => (
                                            <div className="contBox" key={country.country}>
                                                <span
                                                    className={`fi fi-${country.country_code?.toLowerCase()} me-4`}
                                                ></span>
                                                <div className="contName cursor-pointer">
                                                    <div
                                                        className="lang"
                                                        style={{
                                                            "--accent_color3":
                                                                colorObj?.accent_color3,
                                                        }}
                                                    >
                                                        {country.language.map((lang) => (
                                                            <div
                                                                className={
                                                                    country.language.length > 1
                                                                        ? "country-row"
                                                                        : ""
                                                                }
                                                                key={`country-row-${lang}`}
                                                            >
                                                                <span
                                                                    className="country-hover"
                                                                    style={{
                                                                        color: colorObj?.accent_color1,
                                                                        "--primary_color":
                                                                            colorObj?.primary_color,
                                                                    }}
                                                                >
                                                                    {country.country}
                                                                </span>
                                                                <div
                                                                    onClick={() =>
                                                                        changeLanguage(
                                                                            lang.code,
                                                                            lang.direction,
                                                                        )
                                                                    }
                                                                    style={{
                                                                        color: colorObj?.accent_color3,
                                                                    }}
                                                                >
                                                                    {lang.label}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default FooterComponent3;
