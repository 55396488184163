import React, { useEffect, useState } from "react";
import "./FeaturedEvents2.css";
import { Button } from "../Button";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import FeaturedEventCard2 from "../Card/FeaturedEventCard2";
import { useGetLink } from "../../utils/useOpenLink";
import parse from "html-react-parser";
import CustomLink from "../../utils/customLink";

function FeaturedEvents2({ contents: contentToRender = [], heading, colors: colorObj }) {
    const { direction } = useCustomTranslation();

    const [keysArr, setKeysArr] = useState([]);

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const { getLink } = useGetLink();

    useEffect(() => {
        const fetchDefaultData = () => {
            const resp = content[contentToRender[0].contentKey].map((k) => content[k]);

            if (resp.length) {
                setKeysArr(Object.keys(resp));
                setContentReferenceData(resp);
            }
        };
        fetchDefaultData();
    }, []);

    const swiperCarousel = {
        spaceBetween: 10,
        slidesPerView: 2.3,
        modules: [Pagination, Navigation],
        pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 7 },
        navigation: true,
        breakpoints: {
            345: {
                slidesPerView: 1.3,
                spaceBetween: 20,
            },
            576: {
                slidesPerView: 1.3,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 1.3,
            },
            1024: {
                spaceBetween: 20,
                slidesPerView: 3,
            },
            1280: {
                spaceBetween: 20,
                slidesPerView: 2.3,
            },
        },
    };

    return (
        <>
            <div className="featuredEvents2Section py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-sm-12 col-12">
                            <div className="textMainHldr">
                                <div className="heading" style={{ color: colorObj?.primary_color }}>
                                    {heading}
                                </div>
                                <div
                                    className="sub-heading"
                                    style={{ color: colorObj?.accent_color1 }}
                                >
                                    {contentToRender[0]?.heading}
                                </div>
                                <div
                                    className="content-body"
                                    style={{ color: colorObj?.accent_color1 }}
                                >
                                    {contentToRender[0]?.body && parse(contentToRender[0]?.body)}
                                </div>

                                {contentToRender[0]?.content_buttons.length > 0 &&
                                    contentToRender[0]?.content_buttons.map((v, i) => (
                                        <CustomLink to={getLink(v.action)} key={v.label}>
                                            <div className="mt-3" key={i}>
                                                <Button
                                                    className="button"
                                                    style={{
                                                        color: colorObj?.accent_color4,
                                                        background: colorObj?.primary_color,
                                                    }}
                                                >
                                                    {v.label}
                                                    <i className="pi pi-angle-right ms-3"></i>
                                                </Button>
                                            </div>
                                        </CustomLink>
                                    ))}
                            </div>
                        </div>

                        <div className="col-md-8 col-lg-8 col-sm-12 col-12 pe-0">
                            <Swiper
                                dir={direction}
                                key={direction}
                                {...swiperCarousel}
                                style={{
                                    "--primary_color": colorObj?.primary_color,
                                    "--accent_color4": colorObj?.accent_color4,
                                }}
                            >
                                {keysArr.map((value) => (
                                    <SwiperSlide key={value}>
                                        <FeaturedEventCard2
                                            content={contentReferenceData[value]}
                                            key={value}
                                            colors={colorObj}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeaturedEvents2;
