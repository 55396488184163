import React from "react";
import { Button } from "../Button";
import moment from "moment";
import { useGetLink } from "../../utils/useOpenLink";
import ImageWithObjectFit from "../ImageWrapperComponent";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { defaultEventImage } from "../../utils/theme";
import { Link, useNavigate } from "react-router-dom";

function EventCard({ content, colorsObj }) {
    const { image, title, register, start, tags, venue } = content;

    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    const navigate = useNavigate();

    return (
        <>
            <div className="newsCard card">
                <div className="cardImg">
                    <ImageWithObjectFit
                        className="cursor-pointer"
                        src={image.src ? image.src : defaultEventImage}
                    />
                </div>
                <div className="card-body d-flex flex-column text-start">
                    <div className="tag-heading mb-2" style={{ color: colorsObj.primary_color }}>
                        {tags}
                    </div>
                    <h3
                        className="underline mb-5"
                        style={{ color: colorsObj.accent_color1 }}
                        onClick={() =>
                            navigate(`/event-detail`, {
                                state: { eventDetails: content, deafultImage: defaultEventImage },
                            })
                        }
                    >
                        {title}
                    </h3>
                    <div className="eventDate" style={{ color: colorsObj.accent_color3 }}>
                        <i className="pi pi-calendar"></i>
                        <span className="ms-2"></span>
                        {moment(start).format("ddd DD MMMM [•] h:mm A")}
                    </div>
                    {venue !== "" && (
                        <div className="eventDate mt-2" style={{ color: colorsObj.accent_color3 }}>
                            <i className="pi pi-map-marker"></i>
                            <span className="ms-2">{venue}</span>
                        </div>
                    )}
                    {register !== "" && (
                        <div className="eventBtn mt-auto mb-2">
                            <Link to={getLink(register)}>
                                <Button
                                    className="button max-w-auto p-2 px-6"
                                    style={{
                                        color: colorsObj.accent_color4,
                                        background: colorsObj.primary_color,
                                    }}
                                >
                                    {t("btnText.RegisterNow")}
                                    <i className="fa fa-angle-right ms-2" aria-hidden="true"></i>
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default EventCard;
