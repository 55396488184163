import parse from "html-react-parser";
import React from "react";
import { useGetLink } from "../../utils/useOpenLink";
import { Link } from "react-router-dom";
import useCustomTranslation from "../../utils/useCustomTranslation";

function FeaturedItem({ heading, data }) {
    const { getLink } = useGetLink();
    const { t } = useCustomTranslation();

    if (!data) {
        return null;
    }

    return (
        <div className="max-w-96 bg-accent_color2 shrink-0 flex-grow basis-60 xl:block hidden py-12 px-10 rtl-linkMain">
            <div className="text-primary_color uppercase font-medium mb-2 featured-title">
                {heading}
            </div>
            <Link to={getLink(data.path)}>
                <img
                    className="rounded-xl cursor-pointer mb-2 w-full object-cover max-h-56"
                    src={data.image.src}
                />
            </Link>
            <div className="text-primary_color text-sm mb-2">{data.title}</div>
            <div className="text-accent_color1 text-sm mb-2 featured-summary">
                {data.summary && parse(data.summary)}
            </div>

            <Link to={getLink(data.path)}>
                <div className="text-accent_color1 cursor-pointer font-semibold text-sm">
                    {t("btnText.ReadMore")}
                    <i className="pi pi-arrow-right mx-2 text-accent_color1" aria-hidden="true"></i>
                </div>
            </Link>
        </div>
    );
}
export default FeaturedItem;
