import React, { useEffect, useState } from "react";
import "./HeroImageMenu.css";
import parse from "html-react-parser";
import { useGetLink } from "../../utils/useOpenLink";
import { Dropdown } from "primereact/dropdown";
import CustomLink from "../../utils/customLink";

function HeroImageMenu({
    heading,
    body,
    section_image,
    contents: contentsArray,
    colors: colorObj,
}) {
    const { getLink } = useGetLink();

    const [selectedLink, setSelectedLink] = useState(null);
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const [placeholder, setPlaceholder] = useState("");

    useEffect(() => {
        if (contentsArray) {
            const options = contentsArray.flatMap((value) =>
                value.contents && value.contents.length > 0
                    ? value.contents.map((v) => ({
                          label: v.heading,
                          value: v.content_reference,
                          type: v.type,
                      }))
                    : [],
            );
            setDropdownOptions(options);
            setPlaceholder(contentsArray[0]?.heading);
        }
    }, [contentsArray]);

    const handleDropdownChange = (e) => {
        const selectedOption = e.value;
        setSelectedLink(selectedOption);
        const selectedItem = dropdownOptions.find((option) => option.value === selectedOption);
        getLink(selectedItem.value, selectedItem.type);
    };
    return (
        <>
            <section className="heroMenuWrapper my-30">
                <div
                    className="rightMenuHldr"
                    style={{
                        "--accent_color4": "red",
                        "--accent_color1": colorObj?.accent_color1,
                        "--accent_color3": colorObj?.accent_color3,
                    }}
                >
                    <ul>
                        {contentsArray &&
                            contentsArray.map((value) => (
                                <li className="title" key={value.heading}>
                                    {value.heading}
                                    {value.contents?.length > 0 &&
                                        value.contents.map((v, i) => (
                                            <CustomLink
                                                to={getLink(v.content_reference)}
                                                type={v?.type}
                                                key={i}
                                            >
                                                <li className="cursor-pointer links mt-3">
                                                    <span>{v.heading}</span>
                                                    <i className="pi pi-arrow-down"></i>
                                                </li>
                                            </CustomLink>
                                        ))}
                                </li>
                            ))}
                    </ul>
                </div>
                <div
                    className="titleHldr container"
                    style={{ "--accent_color4": colorObj?.accent_color4 }}
                >
                    <h4>{heading}</h4>
                    <h2>{parse(body)}</h2>
                </div>

                <div className="heroImgMain">
                    <img src={section_image?.src}></img>
                </div>
            </section>
            <div className="heroMenuDropHldr">
                <Dropdown
                    value={selectedLink}
                    onChange={handleDropdownChange}
                    options={dropdownOptions}
                    optionLabel="label"
                    placeholder={placeholder}
                    className="w-full md:w-14rem"
                    style={{ "--accent_color4": colorObj?.accent_color4 }}
                />
            </div>
        </>
    );
}

export default HeroImageMenu;
