import clsx from "clsx";
import React, { useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { selectCountriesLanguageArray, selectCountryLanguageCodes } from "../../redux/authSlice";
import { useClickOutside } from "../../utils/useClickOutside";
import Modal from "../Modal";
import { GLOBAL_SOCIAL_LINKS } from "../../constants/globalValues";
import "./SocialLinks.css";

function SocialLinks() {
    const countriesLanguageData = useSelector(
        selectCountriesLanguageArray,
        (prev, next) => prev.length === next.length,
    );

    const { countryCode: countryCodeFromState } = useSelector(
        selectCountryLanguageCodes,
        shallowEqual,
    );
    const socialMediaData =
        countriesLanguageData.find(
            (d) => d.country_code?.toLowerCase() === countryCodeFromState?.toLowerCase(),
        )?.social || {};

    const dropdownRef = useRef(null);

    const [showModal, setShowModal] = useState(false);

    useClickOutside(dropdownRef, () => {
        setShowModal(false);
    });

    const renderLinkGroup = ({ title, data = {}, className }) => {
        const keys = Object.keys(data);

        return keys?.length ? (
            <div className={className}>
                <div className="text-black2 font-medium mb-1">{title}</div>
                <div className="flex flex-row gap-x-6 text-xl">
                    {keys.map((key) => {
                        const icon = key == "x" ? "twitter-x" : key;
                        return (
                            <a
                                key={key}
                                className="text-grey2"
                                target="_blank"
                                rel="noreferrer"
                                href={data[key]}
                            >
                                <i className={clsx("bi fa-fw", `bi-${icon}`)} />
                            </a>
                        );
                    })}
                </div>
            </div>
        ) : null;
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <div
                className="flex flex-row items-center justify-center gap-x-8 p-2 mt-4 text-primary_color cursor-pointer text-3xl social-links"
                onClick={() => {
                    setShowModal(true);
                }}
            >
                <i className="pi pi-facebook fa-fw" />
                <i className="pi pi-twitter fa-fw" />
                <i className="pi pi-instagram fa-fw" />
                <i className="pi pi-youtube fa-fw" />
            </div>
            {showModal && (
                <Modal
                    setShowModal={setShowModal}
                    className="md:bottom-14 md:inset-auto md:right-0 md:mt-8"
                >
                    {renderLinkGroup({
                        title: "Global",
                        data: socialMediaData?.global || GLOBAL_SOCIAL_LINKS,
                        className: "mb-8",
                    })}
                    {renderLinkGroup({ title: "Country", data: socialMediaData.country })}
                </Modal>
            )}
        </div>
    );
}

export default SocialLinks;
