import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hash } from "../../utils/hashFunctions";
import { useGetLink } from "../../utils/useOpenLink";
import FeaturedItem from "./FeaturedItem";
import { TabPanel, TabView } from "primereact/tabview";
import "./MegaMenu.css";
import { fetchFromApi } from "../../redux/layoutSlice";
import { WindowContext } from "../WindowResize/resize";

const MIN_ITEMS_PER_ROW = 4;
const MIN_ITEMS_PER_ROW_MOBILE = 3;

function SubMenuStyle1({ data }) {
    const { getLink } = useGetLink();
    const dispatch = useDispatch();

    const { isMobile } = useContext(WindowContext);
    const ITEMS_PER_ROW = isMobile ? MIN_ITEMS_PER_ROW_MOBILE : MIN_ITEMS_PER_ROW;

    const [mainArr, setMainArr] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const [featuredItemData, setFeaturedItemData] = useState(null);

    const [featuredItem, setFeaturedItem] = useState(null);

    useEffect(() => {
        if (data && data.contents) {
            let tempArr = [];
            for (let i = 0; i < data.contents.length; i++) {
                const item = data.contents[i];
                if (item.feature === 1) {
                    setFeaturedItem(item);
                } else {
                    tempArr.push(item);
                }
            }
            setMainArr(tempArr);
        }
    }, [data]);

    useEffect(() => {
        const fetchDefaultData = async () => {
            const respKeys = content[hash(featuredItem.content_reference)];
            let resp;
            if (respKeys) resp = respKeys?.map((k) => content[k]);
            else {
                const { payload } = await dispatch(
                    fetchFromApi({ link: featuredItem.content_reference }),
                );
                resp = payload.data;
            }
            setFeaturedItemData(resp?.length > 0 && resp[0]);
        };

        if (featuredItem) fetchDefaultData();
    }, [featuredItem]);

    const renderLinkSection = (contentItem, index) => {
        const linkHtml = [];
        let featuredLink;
        for (let i = 0; i < contentItem.contents.length; i++) {
            const link = contentItem.contents[i];
            if (link.feature === 1) {
                featuredLink = link;
            } else if (link.sub_content_image) {
                linkHtml.push(
                    <Link key={i} to={getLink(link.content_reference)}>
                        <div className="relative w-24 md:w-28 h-24 md:h-28 rounded-md overflow-clip">
                            <img
                                className="w-full h-full object-cover"
                                src={link.sub_content_image.src}
                            />
                            <div className="absolute inset-0 bg-dark-overlay flex flex-col justify-end pb-3 text-center text-accent_color3 font-semibold">
                                {link.heading}
                            </div>
                        </div>
                    </Link>,
                );
            }
        }
        return (
            <TabPanel key={index} header={contentItem.heading}>
                <div className="flex justify-center flex-col">
                    <div className="px-0 md:px-0 flex flex-row justify-start md:justify-center gap-x-2 lg:gap-x-10 pb-2 md:pb-6">
                        {linkHtml.slice(0, ITEMS_PER_ROW)}
                    </div>
                    <div className="px-0 md:px-0 flex flex-row justify-start md:justify-center gap-x-2 lg:gap-x-10 pb-2 md:pb-6">
                        {linkHtml.slice(ITEMS_PER_ROW, ITEMS_PER_ROW * 2)}
                    </div>
                    <div className="px-0 md:px-0 flex flex-row justify-start md:justify-center gap-x-2 lg:gap-x-10">
                        {linkHtml.slice(ITEMS_PER_ROW * 2)}
                    </div>
                    {featuredLink ? (
                        <Link
                            className="font-semibold text-sm text-accent_color1"
                            to={getLink(featuredLink.content_reference)}
                        >
                            {featuredLink.heading}
                            <i
                                className="pi pi-arrow-right mx-2 text-accent_color3"
                                aria-hidden="true"
                            ></i>
                        </Link>
                    ) : null}
                </div>
            </TabPanel>
        );
    };

    return (
        <>
            <div className="p-6 lg:p-12 flex flex-col md:flex-row flex-grow-[3] flex-shrink basis-0 flex-wrap justify-around rtl-linkMain">
                <TabView>
                    {mainArr.map((contentItem, index) => renderLinkSection(contentItem, index))}
                </TabView>
            </div>
            <FeaturedItem heading={featuredItem?.heading} data={featuredItemData} />
        </>
    );
}

export default SubMenuStyle1;
