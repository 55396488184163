import clsx from "clsx";
import React from "react";

const Modal = ({ children, className, setShowModal }) => {
    return (
        <div
            className={clsx(
                "fixed md:absolute inset-0 md:inset-auto bg-white shadow-lg md:min-w-[400px] flex flex-col p-8 rounded-xl z-200",
                className,
            )}
        >
            <div className="relative text-end -mr-2 md:-mt-4">
                <i
                    className="pi pi-times cursor-pointer text-4xl md:text-2xl self-end text-accent_color1_default"
                    onClick={() => setShowModal(false)}
                ></i>
            </div>
            {children}
        </div>
    );
};

export default Modal;
