import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import fonts from "./fonts";
import { shallowEqual, useSelector } from "react-redux";
import { selectCountryLanguageCodes } from "../redux/authSlice";

function MetaTags({ pageType = "website", summary }) {
    const metaTags = useSelector((state) => state.layout.metaTags);

    const contentImage = useSelector((state) => state.layout.contentImage);

    const title = useSelector((state) => state.layout.title);

    const { languageCode } = useSelector(selectCountryLanguageCodes, shallowEqual);

    useEffect(() => {
        document.body.style.fontFamily = fonts[languageCode] || fonts.en;
    }, [languageCode]);

    return (
        <div>
            <Helmet
                htmlAttributes={{
                    lang: languageCode,
                }}
            >
                {(metaTags || []).map((v, i) => (
                    <meta name={v.meta_tag?.toLowerCase()} content={v.meta_content} key={i} />
                ))}
                <meta property="og:title" content={title} />
                <meta
                    property="og:description"
                    content={
                        summary
                            ? summary.replace(/<\/?[^>]+(>|$)/g, "")
                            : "The official website of the Ismaili Muslim community"
                    }
                />
                {contentImage?.src && <meta property="og:image" content={contentImage.src} />}
                <meta property="og:type" content={pageType} />
                {languageCode !== "en" && <meta property="og:locale" content={languageCode} />}
            </Helmet>
        </div>
    );
}

export default MetaTags;
