export const FooterDisplay = ({ setShowModal, countryName, languageCode, regionName }) => {
    return (
        <div
            className="cursor-pointer mb-8"
            onClick={() => {
                setShowModal((e) => !e);
            }}
        >
            <a className="flex items-center justify-center text-sm font-normal border border-solid rounded-xl py-2 px-3 hover:text-accent_color3">
                <i className="pi pi-globe text-accent_color4"></i>
                <span className="mx-2">{`${countryName} ${
                    regionName ? `(${regionName}) ` : ""
                }[${languageCode?.toUpperCase()}]`}</span>
                <i className="pi pi-angle-down"></i>
            </a>
        </div>
    );
};
