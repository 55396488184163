import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hash } from "../../utils/hashFunctions";
import { useGetLink } from "../../utils/useOpenLink";
import FeaturedItem from "./FeaturedItem";
import { fetchFromApi } from "../../redux/layoutSlice";

function SubMenuStyle1({ data }) {
    const { getLink } = useGetLink();
    const dispatch = useDispatch();

    const [mainArr, setMainArr] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const [featuredItemData, setFeaturedItemData] = useState(null);

    const [featuredItem, setFeaturedItem] = useState(null);

    useEffect(() => {
        if (data && data.contents) {
            let tempArr = [];
            for (let i = 0; i < data.contents.length; i++) {
                const item = data.contents[i];
                if (item.feature === 1) {
                    setFeaturedItem(item);
                } else {
                    tempArr.push(item);
                }
            }
            setMainArr(tempArr);
        }
    }, [data]);

    useEffect(() => {
        const fetchDefaultData = async () => {
            const respKeys = content[hash(featuredItem.content_reference)];
            let resp;
            if (respKeys) resp = respKeys?.map((k) => content[k]);
            else {
                const { payload } = await dispatch(
                    fetchFromApi({ link: featuredItem.content_reference }),
                );
                resp = payload.data;
            }
            setFeaturedItemData(resp?.length > 0 && resp[0]);
        };

        if (featuredItem) fetchDefaultData();
    }, [featuredItem]);

    const renderLinkSection = (contentItem, index) => {
        const linkHtml = [];
        let featuredLink;
        for (let i = 0; i < contentItem?.contents?.length || 0; i++) {
            const link = contentItem.contents[i];
            if (link.feature === 1) {
                featuredLink = link;
            } else {
                linkHtml.push(
                    <Link
                        key={i}
                        className="text-sm w-56 pb-2 mb-2"
                        to={getLink(link.content_reference)}
                    >
                        {link.heading}
                    </Link>,
                );
            }
        }

        return (
            <div key={index}>
                <img
                    className="h-11 w-11 object-contain mb-3"
                    src={contentItem.content_image.src}
                    alt={contentItem.content_image.alt}
                    title={contentItem.content_image.title}
                />
                <div className="text-accent_color1 text-sm font-medium uppercase border-solid border-b border-b-accent_color3 pb-3 mb-3">
                    {contentItem.heading}
                </div>
                <div className="flex flex-col flex-wrap flex-1 gap-x-2">{linkHtml}</div>
                {featuredLink ? (
                    <Link
                        className="font-semibold text-sm text-accent_color1"
                        to={getLink(featuredLink.content_reference)}
                    >
                        {featuredLink.heading}
                        <i
                            className="pi pi-arrow-right mx-2 text-accent_color3"
                            aria-hidden="true"
                        ></i>
                    </Link>
                ) : null}
            </div>
        );
    };

    return (
        <>
            <div className="p-6 md:p-12 flex flex-col md:flex-row flex-wrap justify-start gap-x-4 gap-y-8 flex-1 rtl-linkMain">
                {mainArr.map((contentItem, index) => renderLinkSection(contentItem, index))}
            </div>

            <FeaturedItem heading={featuredItem?.heading} data={featuredItemData} />
        </>
    );
}

export default SubMenuStyle1;
