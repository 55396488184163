import GlobalIconSvg from "../../assets/GlobalIconSvg";

export const HeaderDisplay = ({
    setShowModal,
    countryName,
    countryCode,
    languageName,
    regionName,
}) => {
    return (
        <>
            <div
                className="capitalize flex flex-row items-center cursor-pointer"
                onClick={() => {
                    setShowModal((e) => !e);
                }}
            >
                <span className="mr-2 text-lg rounded-lg">
                    {countryName !== "global" ? (
                        <i className={`fi fi-${countryCode?.toLowerCase()}`}></i>
                    ) : (
                        <GlobalIconSvg />
                    )}
                </span>
                {`${countryName}${regionName ? ` (${regionName})` : ""}`}
            </div>
            <div
                className={"hidden md:block cursor-pointer capitalize"}
                onClick={() => {
                    setShowModal((e) => !e);
                }}
            >
                {languageName}
            </div>
            <img
                className="block md:hidden translate-icon cursor-pointer w-6 h-6 object-cover"
                src="/assets/images/translate.svg"
                alt="Translate Icon"
                onClick={() => {
                    setShowModal((e) => !e);
                }}
            />
        </>
    );
};
