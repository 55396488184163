import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCountriesLanguageArray, selectCountryLanguageCodes } from "../redux/authSlice";
import { DEFAULT_COUNTRY_CODE, getValidCountryLanguageCode } from "./countryLanguageHelpers";

export const useOpenLink = () => {
    const navigate = useNavigate();

    const openLink = (link, type, nid) => {
        const finalUrl =
            (type === "link_new" || type === "link") && link.startsWith("http")
                ? link
                : type === "link_new" || type === "link"
                ? `https://${link}`
                : link;
        const destinationPath = link || `/${nid}`;
        switch (type?.toLowerCase()) {
            case "article":
                navigate(`${destinationPath}`);
                break;
            case "speech":
                navigate(`/speech`, { state: { id: link } });
                break;
            default:
                finalUrl.startsWith("http")
                    ? window.open(finalUrl, type === "link_new" ? "_blank" : "_self")
                    : navigate(finalUrl);
        }
    };

    return openLink;
};

export const useGetLink = () => {
    const countriesLanguagesArray = useSelector(
        selectCountriesLanguageArray,
        (prev, next) => prev.length === next.length,
    );
    const { countryCode, languageCode } = useSelector(selectCountryLanguageCodes, shallowEqual);

    const getLink = (linkFromProps = "", type) => {
        // 0. check if external link, return
        // 1. check if link already has a country and/or language
        // 1a. If yes, return link as is
        // 1b. If no, continue
        // 2. Add country code and language from redux
        if (linkFromProps?.startsWith("http") || !linkFromProps) {
            // link is an absolute link, no need to do anything
            return linkFromProps;
        } else if (linkFromProps?.startsWith("www") || type === "link_new" || type === "link") {
            return `https://${linkFromProps}`;
        }
        const link = linkFromProps.startsWith("/") ? linkFromProps.slice(1) : linkFromProps;
        const [countryCodeFromLink] = link.split("/");

        const { countryCode: validCountryCode } = getValidCountryLanguageCode(
            countriesLanguagesArray,
            countryCodeFromLink,
        );

        if (
            countryCodeFromLink?.toLowerCase() === validCountryCode?.toLowerCase() ||
            countryCode === DEFAULT_COUNTRY_CODE
        ) {
            // link is "fully qualified" with country and possibly language
            return link;
        }

        const { languageCode: defaultLanguageCode } = getValidCountryLanguageCode(
            countriesLanguagesArray,
            countryCode,
        );
        // if languageCode === defaultLanguageCode then don't send languageCode
        const langSection =
            languageCode?.toLowerCase() === defaultLanguageCode?.toLowerCase()
                ? ""
                : `/${languageCode?.toLowerCase()}`;
        const linkSection = link && link !== "/" ? `/${link}` : "";
        return `${countryCode?.toLowerCase()}${langSection}${linkSection}`;
    };

    return { getLink };
};

export const useReloadWithCountryLanguage = () => {
    const navigate = useNavigate();

    // const { pathname } = useLocation();

    // const countriesLanguagesArray = useSelector(
    //     selectCountriesLanguageArray,
    //     (prev, next) => prev.length === next.length,
    // );

    // const { countryCode: countryCodeFromState } = useSelector(
    //     selectCountryLanguageCodes,
    //     shallowEqual,
    // );

    // const getIdFromLink = (link = "") => {
    //     const [countryCodeFromLink, languageCodeFromLink, ...idFromLinkArray] = (
    //         link.startsWith("/") ? link.slice(1) : link
    //     ).split("/");

    //     const idFromLink = idFromLinkArray.join("/");

    //     const { countryCode: validCountryCode, languageCode: validLanguageCode } =
    //         getValidCountryLanguageCode(
    //             countriesLanguagesArray,
    //             countryCodeFromLink,
    //             languageCodeFromLink,
    //         );
    //     if (
    //         countryCodeFromLink?.toLowerCase() === validCountryCode?.toLowerCase() &&
    //         languageCodeFromLink?.toLowerCase() === validLanguageCode?.toLowerCase()
    //     ) {
    //         // link is "fully qualified" with country and language
    //         return idFromLink;
    //     }

    //     return link;
    // };

    const reloadPage = () => {
        // const { countryCode: validCountryCode, languageCode: validLanguageCode } =
        //     getValidCountryLanguageCode(
        //         countriesLanguagesArray,
        //         countryCode || countryCodeFromState,
        //         languageCode,
        //     );

        // const idFromLink = getIdFromLink(pathname.slice(1));

        // navigate(
        //     `/${validCountryCode.toLowerCase()}/${validLanguageCode.toLowerCase()}/${idFromLink}`,
        // );
        navigate("/");
        location.reload();
    };

    return { reloadPage };
};
