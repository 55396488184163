import React from "react";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { useGetLink } from "../../utils/useOpenLink";
import { Link } from "react-router-dom";
import { getDateToDisplay } from "../../utils/dateFunctions";
import useGetArticleMeta from "../../utils/useGetArticleMeta";
import PlayIconSvg from "../../assets/PlayIconSvg";

export const TrendingNowCard = ({ content, colorsObj }) => {
    const { image, date, title, path, posterImage, breadcrumb, link } = content;

    const { getLink } = useGetLink();

    const { getTheme, getSection } = useGetArticleMeta();
    const theme = getTheme(breadcrumb);
    const section = getSection(breadcrumb);

    return (
        <>
            <div className="card h-100">
                <div className="cardImg">
                    {!!link && (
                        <div
                            className="playIconHldr"
                            style={{
                                "--accent_color4": colorsObj.accent_color4,
                            }}
                        >
                            <PlayIconSvg />
                        </div>
                    )}
                    <Link to={getLink(path)}>
                        <ImageWithObjectFit
                            className="cursor-pointer"
                            src={image?.src || posterImage}
                        />
                    </Link>
                </div>
                <div className="card-body d-flex flex-column text-start">
                    <div className="tag-heading" style={{ color: colorsObj.primary_color }}>
                        {theme}
                    </div>
                    <Link to={getLink(path)}>
                        <h3
                            className="hover:underline mb-4 cursor-pointer"
                            style={{ color: colorsObj.accent_color1 }}
                        >
                            {title}
                        </h3>
                    </Link>
                    <div className="authorDateMain mt-auto">
                        <span
                            className="author me-1 capitalize"
                            style={{ color: colorsObj.accent_color1 }}
                        >
                            {section}
                        </span>
                        <span className="date" style={{ color: colorsObj.accent_color3 }}>
                            {section && date ? " • " : ""}
                            {getDateToDisplay(date)}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};
