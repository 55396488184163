import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { APP_COLORS } from "../../constants/styles";
import SubMenuStyle1 from "./SubMenuStyle1";
import SubMenuStyle2 from "./SubMenuStyle2";
import SubMenuStyle3 from "./SubMenuStyle3";
import SubMenuStyle4 from "./SubMenuStyle4";
import scroll from "../Header/scroll";
import { useOpenMenu } from "../../utils/useOpenMenu";

const SUBMENU_HEADER_ID = "submenu-header";

function MegaMenu({ subMenuHeader, sections = [], style, onMenuHideChange }) {
    const [subMenuContent, setSubMenuContent] = useState(null);

    const [navBarVisible, setNavBarVisible] = useState(true);

    const scrollTop = scroll();

    const {
        menuRef: subMenuRef,
        handleClick,
        handleMouseEnter,
        handleMouseLeave,
        activeIndex: activeSubMenuId,
    } = useOpenMenu();

    useEffect(() => {
        const isMenuHidden =
            scrollTop.y > 400 && scrollTop.y - scrollTop.lastY > 0 && activeSubMenuId == null;
        setNavBarVisible(!isMenuHidden);

        onMenuHideChange(!isMenuHidden);
    }, [scrollTop.y, scrollTop.lastY]);

    useEffect(() => {
        const contentIds = (subMenuHeader || []).map((value) => parseInt(value.content_reference));
        const subMenuContentItems = new Map(
            sections.filter((item) => contentIds.includes(item.id)).map((item) => [item.id, item]),
        );
        setSubMenuContent(subMenuContentItems);
    }, [subMenuHeader]);

    const renderSubMenu = (data) => {
        if (!data) {
            return null;
        }

        let innerMenu;
        switch (data.layout_type) {
            case "sub_menu_style1":
                innerMenu = <SubMenuStyle1 data={data} />;
                break;
            case "sub_menu_style2":
                innerMenu = <SubMenuStyle2 data={data} />;
                break;
            case "sub_menu_style3":
                innerMenu = <SubMenuStyle3 data={data} />;
                break;
            case "sub_menu_style4":
                innerMenu = <SubMenuStyle4 data={data} />;
                break;
            default:
                return null;
        }

        return (
            <div
                style={APP_COLORS(data.colors)}
                className={
                    "absolute flex left-0 right-0 top-14 pt-3 justify-center max-w-[80%] mx-auto max-h-[calc(100vh-200px)] z-200 animate-fadeInDown text-start"
                }
            >
                <div className="relative bg-white rounded-2xl overflow-y-scroll flex flex-col md:flex-row">
                    {innerMenu}
                </div>
            </div>
        );
    };

    return (
        <div style={style} className={clsx(!navBarVisible ? "menu-hide" : "menu-show")}>
            {activeSubMenuId && (
                <div className="hidden md:block bg-black fixed inset-0 fade-opacity" />
            )}
            <nav
                ref={subMenuRef}
                onMouseLeave={() => handleMouseLeave()}
                className={clsx(
                    "hidden md:flex flex-row justify-center items-center transition-transform ease-linear duration-300 relative bg-primary_color",
                )}
            >
                {(subMenuHeader || []).map((value) => {
                    return (
                        <div className="relative" key={value.heading}>
                            <div
                                id={SUBMENU_HEADER_ID}
                                className="cursor-pointer text-accent_color4 px-4 py-[0.66rem] text-base hover:underline hover:text-accent_color4"
                                onClick={() => handleClick(parseInt(value.content_reference))}
                                onMouseEnter={() =>
                                    handleMouseEnter(parseInt(value.content_reference))
                                }
                            >
                                {value.heading}
                            </div>
                            {activeSubMenuId == parseInt(value.content_reference) && (
                                <div className="absolute left-0 right-0 justify-center flex animate-fadeInDownBehind z-200">
                                    <img className="h-6" src="/assets/images/triangle.svg" />
                                </div>
                            )}
                        </div>
                    );
                })}
                {renderSubMenu(subMenuContent && subMenuContent.get(activeSubMenuId))}
            </nav>
        </div>
    );
}

export default MegaMenu;
