import React, { useContext } from "react";
import "./ArticlesDetails3.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { fetchArticle } from "../../redux/layoutSlice";
import PrintSvg from "../../assets/PrintSvg";
import PDFSvg from "../../assets/PDFSvg";
import WhatsAppSvg from "../../assets/WhatsAppSvg";
import { WindowContext } from "../../components/WindowResize/resize";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { parseWithRemoveBrTagsFromBody, shareOnWhatsapp } from "../../utils/articleHelpers";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { Dropdown } from "primereact/dropdown";
import BreadCrumbs from "../../components/BreadCrumbs";
import { updateFontFamily } from "../../redux/authSlice";
import clsx from "clsx";
import { additionalClassNameFromLanguage } from "../../utils/additionalClassNameFromLanguage";
import MetaTags from "../../utils/metaTagsFunction";

function Articles3({ contents, colors: colorObj }) {
    const { articleId: articleIdFromParams } = useParams();

    const id = contents?.[0]?.id || articleIdFromParams;

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { isMobile } = useContext(WindowContext);

    const [isLoading, setIsLoading] = useState(!!id);

    const [didDispatch, setDidDispatch] = useState(false);

    const [clickOnShareBtn, setClickOnShareBtn] = useState(false);

    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const [currentLangName, setCurrentLangName] = useState();

    const content = useSelector((state) => state.layout.content);

    const fontFamily = useSelector((state) => state.auth.fontFamily);

    const { t } = useCustomTranslation();

    const { title, summary, body, article_pdf, translate_languages, model_type, themes } =
        content[id] || {};

    useEffect(() => {
        if (isLoading && !didDispatch) {
            dispatch(fetchArticle({ id }));
            setDidDispatch(true);
        }
        if (isLoading && content[id]) {
            setIsLoading(false);
        }
    }, [isLoading, content, didDispatch]);

    useEffect(() => {
        if (translate_languages) {
            const index = translate_languages.findIndex((e) => e.path === id);
            if (index !== -1) {
                setSelectedLanguage(translate_languages[index]);
            }
            const currentLangCode = translate_languages[index]?.lang_code;
            setCurrentLangName(translate_languages[index]?.language);
            dispatch(updateFontFamily(currentLangCode));
        }
    }, [id]);

    useEffect(() => {
        if (selectedLanguage) {
            const newPath = `/articles${selectedLanguage.path}`;
            navigate(newPath);

            dispatch(fetchArticle({ id: selectedLanguage.path, lang: selectedLanguage.lang_code }));
        }
    }, [selectedLanguage]);

    return (
        <>
            <MetaTags pageType="article" summary={summary} />

            <div className="articleMainSecation">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <BreadCrumbs modelType={model_type} themes={themes} />
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <h1 className="mb-3 mt-2" style={{ color: colorObj?.accent_color1 }}>
                                {title}
                            </h1>
                            <div className="iconHldr">
                                <div
                                    className="icon-btn"
                                    style={{ color: colorObj?.accent_color3 }}
                                    onClick={() => setClickOnShareBtn(!clickOnShareBtn)}
                                >
                                    <i className="fa fa-share-alt me-2" aria-hidden="true"></i>
                                    {t("mediaArticleString.share")}

                                    {clickOnShareBtn && (
                                        <div className="ShareSocial mt-2">
                                            <span>
                                                <i
                                                    className="pi pi-whatsapp"
                                                    onClick={() => shareOnWhatsapp()}
                                                ></i>
                                            </span>
                                            <span>
                                                <FacebookShareButton url={window.location.href}>
                                                    <i className="pi pi-facebook"></i>
                                                </FacebookShareButton>
                                            </span>
                                            <span>
                                                <TwitterShareButton url={window.location.href}>
                                                    <i className="pi pi-twitter"></i>
                                                </TwitterShareButton>
                                            </span>
                                            <span>
                                                <LinkedinShareButton url={window.location.href}>
                                                    <i className="pi pi-linkedin"></i>
                                                </LinkedinShareButton>
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <ul>
                                    <li className="cursor-pointer" onClick={() => window.print()}>
                                        <PrintSvg />
                                    </li>
                                    <li
                                        className="cursor-pointer"
                                        onClick={() => window.open(article_pdf, "_blank")}
                                    >
                                        <PDFSvg />
                                    </li>
                                </ul>
                                {translate_languages?.length > 1 && (
                                    <Dropdown
                                        options={translate_languages}
                                        optionLabel="language"
                                        value={selectedLanguage}
                                        onChange={(e) => setSelectedLanguage(e.value)}
                                        placeholder="language"
                                        className="w-full md:w-14rem"
                                        style={{ "--font_family": fontFamily }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {isMobile && (
                    <div className="col-12 mt-3 mb-3">
                        <div className="iconHldr-mobile">
                            <ul>
                                <li className="me-4">
                                    <WhatsAppSvg />
                                </li>
                                <li className="me-4">
                                    <PrintSvg />
                                </li>
                                <li className="me-4">
                                    <PDFSvg />
                                </li>
                            </ul>
                        </div>
                    </div>
                )}

                {!!summary && (
                    <div
                        className={clsx(
                            "titleSecation mt-5 mb-5",
                            additionalClassNameFromLanguage(currentLangName),
                        )}
                    >
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                    <div
                                        className="summary"
                                        style={{
                                            color: colorObj?.accent_color1,
                                            borderLeft: `2px solid ${colorObj?.primary_color}`,
                                        }}
                                    >
                                        {parse(summary || "")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {body !== "" && (
                    <div
                        className={clsx(
                            "articleTextSecation",
                            additionalClassNameFromLanguage(currentLangName),
                        )}
                    >
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                    <div>{parseWithRemoveBrTagsFromBody(body || "")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Articles3;
