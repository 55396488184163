import ImageWithObjectFit from "../ImageWrapperComponent";
import "./HeroSectionRight.css";
import parse from "html-react-parser";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import * as posterImage from "../../utils/usePosterImage";
import { APP_COLORS } from "../../constants/styles";
import CustomLink from "../../utils/customLink";
import { ARTICLE_DETAILS, BLOCK_DETAILS } from "../../constants/strings";

function HeroSectionRight({
    contents: contentToRender,
    heading,
    body,
    section_image,
    colors: colorObj,
    block_buttons,
    block_source,
}) {
    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    const [heroSectionRightDetails, setHeroSectionRightDetails] = useState({
        heading: "",
        body: "",
        path: "",
        image: "",
        nid: "",
    });

    useEffect(() => {
        if (block_source === BLOCK_DETAILS) {
            setHeroSectionRightDetails({
                ...heroSectionRightDetails,
                heading: heading,
                body: body,
                image: section_image?.src,
            });
        }

        if (block_source === ARTICLE_DETAILS) {
            setHeroSectionRightDetails({
                ...heroSectionRightDetails,
                heading: contentReferenceData[0]?.title,
                body: contentReferenceData[0]?.summary,
                path: contentReferenceData[0]?.path,
                image: contentReferenceData[0]?.image?.src || contentReferenceData[0]?.posterImage,
                nid: contentReferenceData[0]?.nid,
            });
        }
    }, [contentReferenceData]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                let resp = respKeys.map((k) => contentFromStore[k]);
                resp = JSON.parse(JSON.stringify(resp));
                for await (const element of resp) {
                    if (element && element.link) {
                        element.posterImage = await posterImage.usePosterImage(
                            element?.link,
                            element?.image,
                        );
                    }
                }
                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, []);

    return (
        <>
            <div className="heroSectionRightCardWrapper py-30" style={APP_COLORS(colorObj)}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="newsCardBig">
                                <div className="row d-flex justify-content-center container">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 pe-lg-0 pe-md-0">
                                        <div className="NewsImg">
                                            <ImageWithObjectFit
                                                src={heroSectionRightDetails.image}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 card-order d-flex align-items-center">
                                        <div className="card-body">
                                            <div className="heading">
                                                {heroSectionRightDetails.heading}
                                            </div>
                                            <div className="summaryText">
                                                {heroSectionRightDetails.body &&
                                                    parse(heroSectionRightDetails.body)}
                                            </div>
                                            <>
                                                {block_source === BLOCK_DETAILS ? (
                                                    <div className="mt-auto">
                                                        {block_buttons.map((v) => (
                                                            <CustomLink
                                                                to={getLink(v.action)}
                                                                key={v.label}
                                                            >
                                                                <div className="btn btn-link button me-3">
                                                                    {v.label}
                                                                </div>
                                                            </CustomLink>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <CustomLink
                                                        to={getLink(heroSectionRightDetails?.path)}
                                                    >
                                                        <div className="btn btn-link button me-3">
                                                            {t("btnText.learnMore")}
                                                        </div>
                                                    </CustomLink>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroSectionRight;
