import React from "react";
import { shareOnWhatsapp } from "../../utils/articleHelpers";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

function ShareOnSocialLinks() {
    return (
        <ul className="socialHldr">
            <li className="cursor-pointer">
                <i className="pi pi-whatsapp" onClick={() => shareOnWhatsapp()}></i>
            </li>
            <li>
                <TwitterShareButton url={window.location.href}>
                    <i className="pi pi-twitter"></i>
                </TwitterShareButton>
            </li>
            <li>
                <LinkedinShareButton url={window.location.href}>
                    <i className="pi pi-linkedin"></i>
                </LinkedinShareButton>
            </li>
            <li>
                <FacebookShareButton url={window.location.href}>
                    <i className="pi pi-facebook"></i>
                </FacebookShareButton>
            </li>
        </ul>
    );
}

export default ShareOnSocialLinks;
