import React, { useCallback, useContext, useEffect, useState } from "react";
import "./EventListing.css";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { Button } from "../Button";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { defaultEventImage } from "../../utils/theme";
import parse from "html-react-parser";
import { useGetLink } from "../../utils/useOpenLink";
import Loader from "../Loader/loader";
import { ScrollTop } from "../../utils/scrollTopFunction";
import { WindowContext } from "../WindowResize/resize";
import useCustomTranslation from "../../utils/useCustomTranslation";
import CustomLink from "../../utils/customLink";

function EventListing({ heading, body, contents: contentToRender = [], colors: colorObj }) {
    const navigate = useNavigate();

    const { t } = useCustomTranslation();

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const [keysArr, setKeysArr] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [eventListResp, setEventListResp] = useState([]);

    const [institutionArr, setInstitutionArr] = useState([]);

    const [categoryArr, setCategoryArr] = useState([]);

    const [isListView, setIsListView] = useState(false);

    const [clickOnSearch, setClickOnSearch] = useState(false);

    const [visibleCount, setVisibleCount] = useState(12);

    const content = useSelector((state) => state.layout.content);

    const { getLink } = useGetLink();

    const { isMobile } = useContext(WindowContext);

    const [filterReq, setFilterReq] = useState({
        institution: "",
        category: "All Categories",
        date: null,
        search: "",
    });

    const fetchDefaultData = useCallback(() => {
        setIsLoading(true);
        const resp = content[contentToRender[0].contentKey].map((k) => content[k]);
        setEventListResp(resp);

        if (resp.length) {
            setKeysArr(Object.keys(resp));
            setContentReferenceData(resp);
        }
        setIsLoading(false);
    }, [content, contentToRender]);

    useEffect(() => {
        fetchDefaultData();
        setFilterReq({
            ...filterReq,
            category: "All Categories",
        });
    }, []);

    useEffect(() => {
        let categoryArry = [];
        let insArr = [];
        let categoryDropdownArry = [];
        let institutionDropdownArry = [];
        for (const key in contentReferenceData) {
            if (Object.hasOwnProperty.call(contentReferenceData, key)) {
                const element = contentReferenceData[key];
                if (element && element.institution !== "") {
                    const index = insArr.findIndex((e) => e === element.institution);
                    if (index === -1) {
                        const newFirstElement = "All Institutions";
                        insArr.push(element.institution);
                        institutionDropdownArry = [newFirstElement].concat(insArr);
                    }
                }

                if (element && element.tags !== "") {
                    const index = categoryArry.findIndex((e) => e === element.tags);
                    if (index === -1) {
                        const newFirstElement = "All Categories";
                        categoryArry.push(element.tags);
                        categoryDropdownArry = [newFirstElement].concat(categoryArry);
                    }
                }
            }
        }
        setInstitutionArr(institutionDropdownArry);
        setCategoryArr(categoryDropdownArry);
    }, [contentReferenceData, eventListResp, filterReq]);

    useEffect(() => {
        if (
            filterReq.category !== "" ||
            filterReq.institution !== "" ||
            filterReq.date !== null ||
            filterReq.search !== ""
        ) {
            if (eventListResp) {
                let originalArr = Object.keys(eventListResp);

                if (filterReq.category !== "") {
                    originalArr =
                        filterReq.category !== "All Categories"
                            ? originalArr.filter(
                                  (v) => contentReferenceData[v].tags === filterReq.category,
                              )
                            : Object.keys(eventListResp);
                }

                if (filterReq.institution !== "" && filterReq.institution !== "All Institutions") {
                    originalArr = originalArr.filter(
                        (v) => contentReferenceData[v].institution === filterReq.institution,
                    );
                }

                if (filterReq.date !== null) {
                    const filterdate = moment(new Date(filterReq.date)).format("YYYY-MM-DD");
                    const date = filterdate + " " + "00:00:00";
                    originalArr = originalArr.filter((v) => contentReferenceData[v].start === date);
                }

                if (filterReq.search !== "") {
                    originalArr = originalArr.filter((v) => {
                        return contentReferenceData[v].title
                            .toLowerCase()
                            .includes(filterReq.search.toLowerCase());
                    });
                }
                setKeysArr(originalArr);
            }
        }
    }, [filterReq]);

    const clearSearch = () => {
        setFilterReq({
            ...filterReq,
            search: "",
        });
        fetchDefaultData();
    };

    const showMoreCards = () => {
        setVisibleCount(keysArr.length);
    };

    const showLessCards = () => {
        setVisibleCount(12);
        setTimeout(() => {
            ScrollTop();
        }, 0);
    };
    return (
        <>
            <Loader isLoading={isLoading} />

            <div className="container upcomEventWrapper">
                <div className="row">
                    <div className="col-12">
                        <h2 style={{ color: colorObj?.accent_color1 }}>{heading}</h2>
                        <div className="findRecord" style={{ color: colorObj?.accent_color1 }}>
                            {parse(body)}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="tabMainHldr">
                            <ul>
                                {categoryArr.map((category, index) => (
                                    <li
                                        key={index}
                                        className={category === filterReq.category ? "active" : ""}
                                        style={{
                                            color:
                                                category === filterReq.category
                                                    ? colorObj?.accent_color4
                                                    : colorObj?.accent_color1,
                                            background:
                                                category === filterReq.category
                                                    ? colorObj?.primary_color
                                                    : colorObj?.accent_color4,
                                        }}
                                        onClick={() =>
                                            setFilterReq({
                                                ...filterReq,
                                                category: category,
                                            })
                                        }
                                    >
                                        {category}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="searchFilter">
                            <div className="searchHldr">
                                <i
                                    className="pi pi-search searchIcon"
                                    style={{ color: colorObj?.primary_color }}
                                ></i>
                                {filterReq.search !== "" && (
                                    <span onClick={clearSearch}>
                                        <i
                                            className="fa fa-close cursor-pointer fa-close"
                                            style={{ color: colorObj?.primary_color }}
                                        ></i>
                                    </span>
                                )}
                                <input
                                    type="text"
                                    className="searchMain"
                                    placeholder={t("constantString.enterKeyWord")}
                                    onChange={(e) =>
                                        setFilterReq({
                                            ...filterReq,
                                            search: e.target.value,
                                        })
                                    }
                                    value={filterReq.search}
                                />
                            </div>
                            {clickOnSearch && isMobile && (
                                <div className="openInputSearch">
                                    <i
                                        className="pi pi-arrow-left"
                                        style={{ color: colorObj?.primary_color }}
                                        onClick={() => setClickOnSearch(false)}
                                    ></i>
                                    <div className="SearchInputHldr">
                                        <i
                                            className="pi pi-search"
                                            style={{ color: colorObj?.primary_color }}
                                        ></i>
                                        <input
                                            type="text"
                                            value={filterReq.search}
                                            onChange={(e) =>
                                                setFilterReq({
                                                    ...filterReq,
                                                    search: e.target.value,
                                                })
                                            }
                                            placeholder={t("")}
                                        />
                                    </div>
                                </div>
                            )}

                            <span
                                className="mobileSearchHldr"
                                onClick={() => setClickOnSearch(true)}
                            >
                                <i
                                    className="pi pi-search"
                                    style={{ color: colorObj?.primary_color }}
                                ></i>
                            </span>

                            <div className="filterHldr">
                                <div className="date">
                                    <i
                                        className="fa fa-calendar"
                                        aria-hidden="true"
                                        style={{ color: colorObj?.accent_color3 }}
                                    ></i>
                                    <Calendar
                                        value={filterReq.date}
                                        onChange={(e) =>
                                            setFilterReq({
                                                ...filterReq,
                                                date: e.value,
                                            })
                                        }
                                        className="customDate"
                                        placeholder={t("placeHolder.date")}
                                        showButtonBar
                                        style={{ "--primary_color": colorObj?.primary_color }}
                                    />
                                </div>

                                <div className="PrimaryAudience">
                                    {institutionArr.length !== 0 && (
                                        <div
                                            className="institution me-2"
                                            style={{ color: colorObj?.accent_color3 }}
                                        >
                                            <i className="fa fa-user-o" aria-hidden="true"></i>
                                            <Dropdown
                                                onChange={(e) =>
                                                    setFilterReq({
                                                        ...filterReq,
                                                        institution: e.value,
                                                    })
                                                }
                                                options={institutionArr}
                                                optionLabel=""
                                                placeholder={t("placeHolder.institution")}
                                                value={filterReq.institution}
                                                className="customDropdown"
                                                style={{
                                                    "--primary_color": colorObj?.primary_color,
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="gridListHldr">
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => setIsListView(false)}
                                    >
                                        <i
                                            className="pi pi-th-large"
                                            style={{
                                                color: isListView
                                                    ? colorObj?.accent_color1
                                                    : colorObj?.primary_color,
                                            }}
                                        ></i>
                                    </span>
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => setIsListView(!isListView)}
                                    >
                                        <i
                                            className="fa fa-align-left ms-2"
                                            style={{
                                                color: !isListView
                                                    ? colorObj?.accent_color1
                                                    : colorObj?.primary_color,
                                            }}
                                        ></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {keysArr && keysArr.length !== 0 && (
                        <>
                            <div className="col-12">
                                <div className={`row ${isListView ? "eventListView" : ""}`}>
                                    {keysArr.slice(0, visibleCount).map((value) => (
                                        <>
                                            <div
                                                className={`${
                                                    isListView
                                                        ? "col-md-6 col-lg-6 col-sm-12 col-12 mt-3 mb-3"
                                                        : "col-md-4 col-lg-4 col-sm-4 col-12 mt-3 mb-3"
                                                }`}
                                                key={value}
                                            >
                                                <div className="newsCard">
                                                    <div className="cardImg">
                                                        <ImageWithObjectFit
                                                            className="cursor-pointer"
                                                            src={
                                                                contentReferenceData[value].image
                                                                    .src
                                                                    ? contentReferenceData[value]
                                                                          .image.src
                                                                    : defaultEventImage
                                                            }
                                                        />
                                                    </div>
                                                    <div className="card-body d-flex flex-column text-start">
                                                        <div
                                                            className="tag-heading mb-2"
                                                            style={{
                                                                color: colorObj?.primary_color,
                                                            }}
                                                        >
                                                            {contentReferenceData[value].tags}
                                                        </div>
                                                        <h3
                                                            className="underline mb-5"
                                                            style={{
                                                                color: colorObj?.accent_color1,
                                                            }}
                                                            onClick={() =>
                                                                navigate(`/event-detail`, {
                                                                    state: {
                                                                        eventDetails:
                                                                            contentReferenceData[
                                                                                value
                                                                            ],
                                                                        deafultImage:
                                                                            defaultEventImage,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            {contentReferenceData[value].title}
                                                        </h3>
                                                        <div
                                                            className="eventDate"
                                                            style={{
                                                                color: colorObj?.accent_color3,
                                                            }}
                                                        >
                                                            <i className="pi pi-calendar"></i>
                                                            <span className="ms-2"></span>
                                                            {moment(
                                                                contentReferenceData[value].start,
                                                            ).format("ddd DD MMMM [•] h:mm A")}
                                                        </div>

                                                        {contentReferenceData[value].venue !==
                                                            "" && (
                                                            <div
                                                                className="eventDate"
                                                                style={{
                                                                    color: colorObj?.accent_color3,
                                                                }}
                                                            >
                                                                <i className="pi pi-map-marker"></i>
                                                                <span className="ms-2"></span>
                                                                {contentReferenceData[value].venue}
                                                            </div>
                                                        )}

                                                        {contentReferenceData[value].register !==
                                                            "" && (
                                                            <div className="eventBtn mt-auto mb-2">
                                                                <CustomLink
                                                                    to={getLink(
                                                                        contentReferenceData[value]
                                                                            .register,
                                                                    )}
                                                                >
                                                                    <Button
                                                                        style={{
                                                                            color: colorObj.accent_color4,
                                                                            background:
                                                                                colorObj.primary_color,
                                                                        }}
                                                                        className="button max-w-auto mt-2"
                                                                    >
                                                                        {t("btnText.RegisterNow")}
                                                                        <i
                                                                            className="fa fa-angle-right ms-2"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </Button>
                                                                </CustomLink>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                    {visibleCount < keysArr.length && (
                                        <div
                                            className="showMore cursor-pointer"
                                            onClick={showMoreCards}
                                            style={{ color: colorObj?.accent_color1 }}
                                        >
                                            {t("btnText.ShowMore")}
                                            <i className="pi pi-arrow-down ms-2"></i>
                                        </div>
                                    )}

                                    {visibleCount >= keysArr.length && (
                                        <div
                                            className="showMore cursor-pointer"
                                            onClick={showLessCards}
                                            style={{ color: colorObj?.accent_color1 }}
                                        >
                                            {t("btnText.ShowLess")}
                                            <i className="pi pi-arrow-up ms-2"></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
export default EventListing;
