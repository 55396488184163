import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { ALPHABET_SPACE_PATTERN, EMAIL_PATTERN } from "../../utils/pattern";
import { useDispatch, useSelector } from "react-redux";
import { fetchContactUsFormFields, saveContactUsForm } from "../../redux/contactUsSlice";
import useToast from "../../utils/useToast";
import { Toast } from "primereact/toast";
import parse from "html-react-parser";

function ContactUs({ heading, body, section_image, contents }) {
    const dispatch = useDispatch();
    const formFields = useSelector((state) => state.contact_us.formFields);

    const { toastRef, showToast } = useToast();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [formErrors, setFormErrors] = useState({});

    const link = contents?.[0]?.content_reference;

    useEffect(() => {
        if (link) dispatch(fetchContactUsFormFields(link));
    }, [dispatch, link]);

    useEffect(() => {
        if (formFields && formFields.length > 0) {
            const initialFormValues = formFields.reduce((acc, { pk }) => {
                acc[`field_${pk}`] = "";
                return acc;
            }, {});
            setFormValues(initialFormValues);
        }
    }, [formFields]);

    const validateForm = () => {
        const errors = {};

        formFields.forEach(({ pk, title, field_type }) => {
            const fieldValue = formValues[`field_${pk}`];

            if (field_type === "text" || field_type === "email" || field_type === "memo") {
                if (!fieldValue) errors[`field_${pk}`] = `Please enter ${title}`;
                else if (field_type === "text" && !ALPHABET_SPACE_PATTERN.test(fieldValue))
                    errors[`field_${pk}`] = `Please enter a valid ${title}`;
                else if (field_type === "email" && !EMAIL_PATTERN.test(fieldValue))
                    errors[`field_${pk}`] = `Please enter a valid ${title}`;
                else if (field_type === "memo" && fieldValue.length < 3)
                    errors[`field_${pk}`] = `${title} should be at least 3 characters long`;
            }

            if (field_type === "select" && !fieldValue) {
                errors[`field_${pk}`] = `Please select ${title}`;
            }
        });

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const fieldPk = name.replace("field_", "");
        const field = formFields.find((f) => f.pk === Number(fieldPk));
        const { title, field_type } = field || {};
        let errors = { ...formErrors };

        setFormValues({
            ...formValues,
            [name]: value,
        });

        if (field) {
            if (field_type === "text" || field_type === "email" || field_type === "memo") {
                if (!value) {
                    errors[`field_${fieldPk}`] = `Please enter ${title}`;
                } else if (field_type === "text" && !ALPHABET_SPACE_PATTERN.test(value)) {
                    errors[`field_${fieldPk}`] = `Please enter a valid ${title}`;
                } else if (field_type === "email" && !EMAIL_PATTERN.test(value)) {
                    errors[`field_${fieldPk}`] = `Please enter a valid ${title}`;
                } else if (field_type === "memo" && value.length < 3) {
                    errors[`field_${fieldPk}`] = `${title} should be at least 3 characters long`;
                } else {
                    delete errors[`field_${fieldPk}`];
                }
            }

            if (field_type === "select" && !value) {
                errors[`field_${fieldPk}`] = `Please select ${title}`;
            } else if (field_type === "select") {
                delete errors[`field_${fieldPk}`];
            }
        }

        setFormErrors(errors);
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        const payload = {};
        formFields.forEach(({ pk }) => {
            payload[pk] = formValues[`field_${pk}`] || "";
        });

        const data = new FormData();
        Object.entries(payload).forEach(([key, value]) => {
            data.append(key, value);
        });

        try {
            const result = await dispatch(saveContactUsForm({ formData: data }));

            if (result.payload.success) {
                showToast("success", "Success", result.payload.message);
                setFormValues({
                    name: "",
                    email: "",
                    country: "",
                    subject: "",
                    message: "",
                });
            } else {
                showToast("error", "Error", "An error occurred while submitting the form.");
            }
        } catch (error) {
            showToast("error", "Error", "An error occurred while submitting the form.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Toast ref={toastRef} />
            <div className="container contactFormWrapper">
                <div className="row shadow-lg">
                    <div className="col-12 mb-5">
                        <h2>{heading}</h2>
                    </div>

                    <div className="col-12 mb-5">{parse(body)}</div>

                    {section_image?.src && (
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 contactImg">
                            <img src={section_image?.src} />
                        </div>
                    )}

                    <div
                        className={
                            section_image?.src
                                ? "col-lg-6 col-md-6 col-sm-12 col-12"
                                : "col-lg-12 col-md-12 col-sm-12 col-12"
                        }
                    >
                        <div className="row">
                            {formFields.map(({ title, field_type, required, values, pk }) => (
                                <div
                                    className={`col-12 ${
                                        field_type !== "memo" ? "col-lg-6" : ""
                                    } mb-4`}
                                    key={pk}
                                >
                                    <div className="flex flex-column gap-2">
                                        <label className="form-label">
                                            {title} {required && <sup>*</sup>}
                                        </label>
                                        {field_type === "text" && (
                                            <InputText
                                                name={`field_${pk}`}
                                                value={formValues[`field_${pk}`] || ""}
                                                onChange={handleInputChange}
                                            />
                                        )}
                                        {field_type === "email" && (
                                            <InputText
                                                type="email"
                                                name={`field_${pk}`}
                                                value={formValues[`field_${pk}`] || ""}
                                                onChange={handleInputChange}
                                            />
                                        )}
                                        {field_type === "select" && (
                                            <Dropdown
                                                name={`field_${pk}`}
                                                value={formValues[`field_${pk}`] || ""}
                                                options={values.map((value) => ({
                                                    label: value,
                                                    value,
                                                }))}
                                                onChange={handleInputChange}
                                                placeholder={`Select ${title}`}
                                                className="w-full md:w-14rem"
                                            />
                                        )}
                                        {field_type === "memo" && (
                                            <InputTextarea
                                                name={`field_${pk}`}
                                                rows={5}
                                                cols={30}
                                                autoResize
                                                value={formValues[`field_${pk}`] || ""}
                                                onChange={handleInputChange}
                                            />
                                        )}
                                        {formErrors[`field_${pk}`] && (
                                            <small className="p-error">
                                                {formErrors[`field_${pk}`]}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <button
                                        type="button"
                                        className="button"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUs;
